/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('./src/styles/fonts.scss')

exports.onRouteUpdate = () => {
  if (window && window.dataLayer) {
    window.gtag = function () {
      window.dataLayer.push(arguments)
    }

    window.gtag(
      'config',
      'AW-944143239/cOGfCI2Zj4gCEIf3mcID',
      {
        phone_conversion_number: '1300 206 952',
      }
    )
  }
}

//   window._ctq = window._ctq || []

//   window._fdNumber = window._fdNumber || ''

//   window._ctq.push(
//     [
//       'setSiteId',
//       'HU-26761349'
//     ],
//     [
//       'requires',
//       ['main.phoneNumber']
//     ],
//     [
//       'replaceText',
//       /1800\s?XXX\s?XXX/g,
//       'main.phoneNumber',
//       { format: 'n' }
//     ],
//     [
//       'onNumberAvailable',
//       function (num) {
//         if (!document.querySelectorAll) return

//         window._fdNumber = num

//         let tels = document.querySelectorAll('a[href^="tel"]')

//         for (let i = 0; i < tels.length; i++) {
//           tels[i].href = 'tel:' + num
//         }
//       },
//       'main.phoneNumber',
//       { format: 'n' }
//     ],
//     [
//       'trackPageView'
//     ]
//   )

//   let wca = document.createElement('script')
//   wca.type = 'text/javascript'
//   wca.async = !0
//   wca.src = 'https://web-call-analytics.com/wca.js'
//   let s = document.getElementsByTagName('script')[0]
//   s.parentNode.insertBefore(wca, s)
